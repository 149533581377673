<template>
    <div class="body-content">
        <input
                type="radio"
                name=""
                class="overlay-hide-radio"
                v-if="this.overlayShow == false"
        />
        <div class="overlay" v-if="this.overlayShow == false">
            <div class="overlay-inner">
                <img src="media/images/swipee.png"/>
                <p>
                    Swipe left and right to <br/>
                    see the all table data
                </p>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-xl-12 col-md-12 col-sm-12 mb-3 ddd custom-zIndex">
                <p
                        class="table-head last_refreshed"
                        style="margin-top: 40px !important"
                >
                    Performance Overview
                </p>
                <div class="btn-div mt-5">
                    <div class="update-btn">
                        <b-button v-b-modal.modal-1 style="width: 147px; height: auto"
                        >Last Update Date
                        </b-button>
                        <p
                                style="
                margin-bottom: 0px !important;
                font-weight: normal;
                color: #000 !important;
              "
                        >
                            {{ moment(filter.start_date).format("YYYY/MM/DD")

                            }}{{ " " }}-
                            {{ " "

                            }}{{ moment(filter.end_date).format("YYYY/MM/DD") }}
                        </p>
                        <!--<span data-v-6f32916d=""-->
                        <!--&gt;Last Refreshed 2 mins ago<i-->
                        <!--data-v-6f32916d=""-->
                        <!--class="fas fa-redo-alt"-->
                        <!--&gt;</i-->
                        <!--&gt;</span>-->
                        <p class="crone-alert" v-if="showCronJobMessage">
                            Please wait your latest converstion are on progress
                        </p>
                    </div>
                </div>
                <b-modal
                        id="modal-1"
                        v-model="advertiserModalShow"
                        title="Performance Overview"
                >
                    <Modal/>
                    <!-- <p >Content goes here...</p> -->
                </b-modal>
                <span class="last_seen"> </span>
            </div>
            <div class="col-xl-4 pt-0 plan-boxes">
        <span class="plan-box">
          <div class="content-box">
            <h1>
              <div v-if="loading">
                <div class="spinner-border"></div>
              </div>
              <div v-else>
                {{ totalconversionvariable }}
              </div>
            </h1>

            <span>{{ total_conversions_title }}</span>
              <!-- <span style="font-size:12px">Approved + Pending = {{dashboard.approved_conver}} + {{dashboard.pending_conver}}</span> -->
          </div>
        </span>
                <span class="plan-box">
          <div class="content-box">
            <h1>
              <div v-if="loading">
                <div class="spinner-border"></div>
              </div>
              <div v-else>AED {{totalpayouts}}</div>
            </h1>
            <span>{{ total_payout_title }}</span>
          </div>
        </span>
            </div>
            <div class="col-xl-8 pt-0 dash-z-index">
                <div
                        class="inf-table dash-table pt-12 px-md-12 px-0"
                        :class="
            this.filterModalShow || this.advertiserModalShow
              ? 'display-none'
              : ''
          "
                >
                    <div class="tabs py-6 px-md-0 px-2">
                        <div cols="6" class="text-capitalize text-left my-0 py-0">
                            <p
                                    style="font-size: 22px"
                                    class="table-head table_res_head my-0 py-0"
                            >
                                <!--{{ selected_tab }}-->
                                <br/>
                            </p>
                        </div>
                        <v-spacer class="table_res_head"></v-spacer>
                        <v-btn-toggle
                                class="flex-wrap"
                                v-model="selected_tab"
                                active-class="tabs_parent"
                                mandatory
                                id="parent" on
                                group
                                @change="handleTabChange"
                        >
                            <v-btn
                                    value="dashboard"
                                    class="text-capitalize toggle_buttons"
                                    active-class="tab_buttons"
                                    @click="selected_tab = 'dashboard'"
                            >
                                Dashboard
                            </v-btn>
                            <v-btn
                                    value="summary"
                                    class="text-capitalize toggle_buttons"
                                    active-class="tab_buttons"
                                    @click="selected_tab = 'summary'"
                            >
                                Summary
                            </v-btn>
                            <v-btn
                                    value="order"
                                    class="text-capitalize toggle_buttons"
                                    active-class="tab_buttons"
                                    @click="selected_tab = 'order'"
                            >
                                Orders
                            </v-btn>
                            <v-btn
                                    value="My Coupons"
                                    class="text-capitalize toggle_buttons"
                                    active-class="tab_buttons"
                                    @click="selected_tab = 'My Coupons'"
                            >
                                My Coupons
                            </v-btn>
                            <v-btn
                                    width="76.22px"
                                    value="offers"
                                    class="text-capitalize toggle_buttons"
                                    active-class="tab_buttons"
                                    @click="selected_tab = 'offers'"
                            >
                                Offers
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                    <Loader
                            v-if="is_loading"
                            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              min-height: 300px;
            "
                            v-bind:logo="loaderLogo"
                    ></Loader>
                    <div v-else>
                        <!--<div v-if="selected_tab == 'invoice'" class="i-mobile-data-grid">-->
                        <!--<invoice />-->
                        <!--</div>-->

                        <div
                                class="i-mobile-data-grid"
                        >
                            <ChartComponent ref="childCompo" v-show="selected_tab == 'dashboard'"
                                            :filterApplied="filterApplied"
                                            :filterData="propsFilter"
                                            @update-parent-variable="updateTotalConversionVariable"

                            />
                        </div>
                        <div
                                class="data_grid d_data_grid mt-20"
                                v-if="selected_tab == 'offers'"
                        >
                            <v-card>
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="d-flex align-item-center">
                                            <img src="media/svg/offers/offer-list.svg" class="offers-icon mr-3"/>
                                            <router-link to="/offers" class="d-flex align-items-center"
                                                         style="font-size: 16px">CPX Offers list
                                            </router-link>
                                        </div>
                                        <!-- <a
                        style="font-size: 16px"
                        href="https://xd.adobe.com/view/cf564a65-ad0b-46a1-badd-d092579fcaa8-9a05/?fullscreen"
                        target="_blank"
                        >CPX Offers list</a
                      > -->
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="d-flex align-item-center">
                                            <img
                                                    src="media/svg/offers/calender.svg"
                                                    class="offers-icon mr-3"
                                            />
                                            <a
                                                    class="d-flex align-items-center"
                                                    style="font-size: 16px"
                                                    href="https://www.figma.com/proto/EdA7GREHXvVCXDkjkPqHaz/CPX---Marketing-Calendar?page-id[…]&scaling=min-zoom&starting-point-node-id=56%3A2470&mode=design"
                                                    target="_blank"
                                            >Marketing Calendar</a
                                            >
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="d-flex align-item-center">
                                            <img
                                                    src="media/svg/offers/best-seller.svg"
                                                    class="offers-icon mr-3"
                                            />
                                            <a
                                                    class="d-flex align-items-center"
                                                    style="font-size: 16px"
                                                    href="https://drive.google.com/drive/folders/14tHwjfl7MulitA8ibizRAASQU2nXTrev"
                                                    target="_blank"
                                            >Creatives & Best Sellers</a
                                            >
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="d-flex align-item-center">
                                            <img
                                                    src="media/svg/offers/rates.svg"
                                                    class="offers-icon mr-3"
                                            />
                                            <a
                                                    class="d-flex align-items-center"
                                                    style="font-size: 16px"
                                                    href="https://xd.adobe.com/view/4deaa3b9-10eb-4050-b1c0-38a93f7f8bc4-18b9/?fullscreen&hints=off"
                                                    target="_blank"
                                            >Cancellation Rates</a
                                            >
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(item, index) in offerslinks" :key="index">
                                    <v-list-item-content>
                                        <div class="d-flex align-item-center">
                                            <img
                                                    :src="`media/svg/offers/${item.icon}`"
                                                    class="offers-icon mr-3"
                                            />
                                            <a
                                                    class="d-flex align-items-center"
                                                    style="font-size: 16px"
                                                    :href="item.link"
                                                    target="_blank"
                                            >{{ item.label }}</a
                                            >
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </div>
                        <div class="data_grid mt-20" v-if="selected_tab == 'summary'"></div>
                        <div class="data_grid mt-20" v-if="selected_tab == 'order'"></div>

                        <!-- Coupons Table -->

                        <div
                                class="data_grid d_data_grid mobile-data-grid"
                                v-if="selected_tab == 'My Coupons'"
                        >
                            <div v-if="couponsData.coupons && couponsData.coupons.length > 0">
                                <div
                                        v-if="loadcoupons"
                                        style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 220px;
                  "
                                >
                                    <v-progress-circular
                                            :size="50"
                                            color="primary"
                                            indeterminate
                                    ></v-progress-circular>
                                </div>
                                <datatable
                                        v-else
                                        :columns="coupons_columns"
                                        :data="couponsData.coupons"
                                        class="table table-bordered mt-12"
                                >
                                    <template slot-scope="{ row }">
                                        <tr class="couponClass">
                                            <td class="advertise">{{ row.advertiser }}</td>
                                            <td class="for-desk">{{ row.coupon }}</td>
                                            <!-- <td>
                    {{ row.assignment_date }}
                    </td> -->
                                            <td class="for-desk">{{ row.coupon_offering }}</td>
                                            <td>
                                                <div v-if="row.status == 'Active'">
                                                    <label for="" class="active_status text-nowrap">{{
                                                        row.status
                                                        }}</label>
                                                </div>
                                                <div v-if="row.status == 'In-Active'">
                                                    <label for="" class="expired_status text-nowrap">{{
                                                        row.status
                                                        }}</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="offers" v-if="exportFile">
                                            <td colspan="4">{{ row.coupon_offering }}</td>
                                        </tr>
                                    </template>
                                </datatable>
                            </div>
                            <div
                                    v-else
                                    class="d-flex mt-3 justify-content-center alert alert-primary"
                            >
                                <b>No Records</b>
                            </div>
                        </div>
                        <!-- Coupons Table -->

                        <!-- <div class="data_grid" v-if="selected_tab == 'summary'">
            <div v-if="summarytab.length > 0">
              <datatable
                :columns="sum_columns"
                :data="summarytab"
                class="table table-responsive vue_data_grid mt-12"
              >
                <template slot-scope="{ row }">
                  <tr style="width: 100%" class="text-center">
                    <td style="width: 21%">{{ row.advertiser }}</td>
                    <td style="width: 24%">{{ row.coupon_code }}</td>
                    <td style="width: 24%">${{ row.thumb }}</td>
                    <td style="width: 23%">{{ row.conversions }}</td>
                    <td style="width: 22%">${{ row.refigned_payout }}</td>
                  </tr>
                </template>
              </datatable>
            </div>
            <div
              v-else
              class="d-flex mt-3 justify-content-center alert alert-primary"
            >
              <b>No Records</b>
            </div>
          </div> -->
                        <div
                                class="data_grid mobile-data-grid"
                                v-if="selected_tab == 'summary'"
                        >
                            <div v-if="summarytab.length > 0">
                                <table class="table table-responsive vue_data_grid mt-12">
                                    <thead>
                                    <th class="light_gray">ADVERTISER</th>
                                    <th class="light_gray">COUPON</th>
                                    <th class="light_gray">
                                        <a
                                                @click="sortBy(true, false)"
                                                v-if="!sumDown && sumUp"
                                                style="
                          color: #000;
                          display: inline-block;
                          margin-left: 10px;
                        "
                                        >
                                            <div class="v-btn light_gray align-center">
                                                SALE AMOUNT
                                                <i
                                                        class="fa fa-arrow-down"
                                                        aria-hidden="true"
                                                        style="
                              color: rgb(200 200 200);
                              font-size: 11px;
                              margin-left: 3px;
                            "
                                                ></i>
                                            </div>
                                        </a>
                                        <a
                                                @click="sortBy(false, true)"
                                                v-if="sumDown && !sumUp"
                                                style="
                          color: #000;
                          display: inline-block;
                          margin-left: 10px;
                        "
                                        >
                                            <div class="v-btn light_gray align-center">
                                                SALE AMOUNT
                                                <i
                                                        class="fa fa-arrow-up"
                                                        aria-hidden="true"
                                                        style="
                              color: rgb(200 200 200);
                              font-size: 11px;
                              margin-left: 3px;
                            "
                                                ></i>
                                            </div>
                                        </a>
                                    </th>
                                    <!-- <th class="light_gray">
                      <a @click="sortByNetConversion(true,false)" v-if="!sumNetConversionDown && sumNetConversionUp" style="color:#000;display: inline-block;margin-left: 10px;">
                           <div class="v-btn light_gray align-center">  NET CONVERSION  <i class="fa fa-arrow-down" aria-hidden="true" style="color: rgb(200 200 200);font-size: 11px;margin-left: 3px;"></i></div>
                      </a>
                      <a @click="sortByNetConversion(false,true)" v-if="sumNetConversionDown && !sumNetConversionUp" style="color:#000;display: inline-block;margin-left: 10px;">
                       <div class="v-btn light_gray align-center"> NET CONVERSION <i class="fa fa-arrow-up" aria-hidden="true" style="color: rgb(200 200 200);font-size: 11px;margin-left: 3px;"></i></div>
                      </a>
                    </th> -->
                                    <th class="light_gray">
                                        <a
                                                @click="sortByConversions(true, false)"
                                                v-if="!sumConversionDown && sumConversionUp"
                                                style="
                          color: #000;
                          display: inline-block;
                          margin-left: 10px;
                        "
                                        >
                                            <div class="v-btn light_gray align-center">
                                                CONVERSIONS
                                                <i
                                                        class="fa fa-arrow-down"
                                                        aria-hidden="true"
                                                        style="
                              color: rgb(200 200 200);
                              font-size: 11px;
                              margin-left: 3px;
                            "
                                                ></i>
                                            </div>
                                        </a>
                                        <a
                                                @click="sortByConversions(false, true)"
                                                v-if="sumConversionDown && !sumConversionUp"
                                                style="
                          color: #000;
                          display: inline-block;
                          margin-left: 10px;
                        "
                                        >
                                            <div class="v-btn light_gray align-center">
                                                CONVERSIONS
                                                <i
                                                        class="fa fa-arrow-up"
                                                        aria-hidden="true"
                                                        style="
                              color: rgb(200 200 200);
                              font-size: 11px;
                              margin-left: 3px;
                            "
                                                ></i>
                                            </div>
                                        </a>
                                    </th>
                                    <!-- <th class="light_gray">
                      <a @click="SortByNetPayout(true,false)" v-if="!sumNetPayoutDown && sumNetPayoutUp" style="color:#000;display: inline-block;margin-left: 10px;">
                       <div class="v-btn light_gray align-center"> NET PAYOUT <i class="fa fa-arrow-down" aria-hidden="true" style="color: rgb(200 200 200);font-size: 11px;margin-left: 3px;"></i></div>
                      </a>
                      <a @click="SortByNetPayout(false,true)" v-if="sumNetPayoutDown && !sumNetPayoutUp" style="color:#000;display: inline-block;margin-left: 10px;">
                       <div class="v-btn light_gray align-center">  NET PAYOUT<i class="fa fa-arrow-up" aria-hidden="true" style="color: rgb(200 200 200);font-size: 11px;margin-left: 3px;"></i></div>
                      </a>
                    </th> -->
                                    <th class="light_gray">
                                        <a
                                                @click="SortByPayout(true, false)"
                                                v-if="!sumPayoutDown && sumPayoutUp"
                                                style="
                          color: #000;
                          display: inline-block;
                          margin-left: 10px;
                        "
                                        >
                                            <div class="v-btn light_gray align-center">
                                                PAYOUT
                                                <i
                                                        class="fa fa-arrow-down"
                                                        aria-hidden="true"
                                                        style="
                              color: rgb(200 200 200);
                              font-size: 11px;
                              margin-left: 3px;
                            "
                                                ></i>
                                            </div>
                                        </a>
                                        <a
                                                @click="SortByPayout(false, true)"
                                                v-if="sumPayoutDown && !sumPayoutUp"
                                                style="
                          color: #000;
                          display: inline-block;
                          margin-left: 10px;
                        "
                                        >
                                            <div class="v-btn light_gray align-center">
                                                PAYOUT<i
                                                    class="fa fa-arrow-up"
                                                    aria-hidden="true"
                                                    style="
                              color: rgb(200 200 200);
                              font-size: 11px;
                              margin-left: 3px;
                            "
                                            ></i>
                                            </div>
                                        </a>
                                    </th>
                                    </thead>
                                    <tbody>
                                    <!-- <datatable
                :columns="sum_columns"
                :data="summarytab"
                class="table table-responsive vue_data_grid mt-12"
              > -->
                                    <template v-for="(row, is) in summarytab">
                                        <template v-if="row.multipleData.length > 1">
                                            <tr style="width: 100%" class="text-center">
                                                <td style="width: 21%; text-align: left !important">
                                                    {{ row.advertiser

                                                    }}<a
                                                        @click="row.showData = true"
                                                        v-if="!row.showData"
                                                        style="
                                color: #000;
                                display: inline-block;
                                margin-left: 10px;
                              "
                                                ><i
                                                        class="fa fa-caret-down"
                                                        aria-hidden="true"
                                                        style="color: #000"
                                                ></i></a
                                                ><a
                                                        @click="row.showData = false"
                                                        v-if="row.showData"
                                                        style="
                                color: #000;
                                display: inline-block;
                                margin-left: 10px;
                              "
                                                ><i
                                                        class="fa fa-caret-up"
                                                        aria-hidden="true"
                                                        style="color: #000"
                                                ></i
                                                ></a>
                                                </td>
                                                <td style="width: 24%" v-if="!row.showData">-</td>
                                                <td style="width: 24%" v-if="!row.showData">
                                                    AED {{ parseFloat(row.thumb).toFixed(2) }}
                                                </td>
                                                <!-- <td style="width: 23%" v-if="!row.showData">{{ row.net_conversion }}</td> -->
                                                <td style="width: 23%" v-if="!row.showData">
                                                    {{ row.conversions }}
                                                </td>
                                                <!-- <td style="width: 22%" v-if="!row.showData">${{ parseFloat(row.refigned_net_payout).toFixed(2) }}</td> -->
                                                <td style="width: 22%" v-if="!row.showData">
                                                    AED {{ parseFloat(row.refigned_payout).toFixed(2) }}
                                                </td>
                                            </tr>
                                            <tr
                                                    style="width: 100%"
                                                    v-for="(subSum, ind) in row.multipleData"
                                                    :key="'subSum' + ind"
                                                    v-if="row.showData"
                                            >
                                                <td style="width: 21%"></td>
                                                <td style="width: 24%">{{ subSum.coupon_code }}</td>
                                                <td style="width: 24%">AED{{ subSum.thumb }}</td>
                                                <!-- <td style="width: 23%">{{ subSum.net_conversion }}</td> -->
                                                <td style="width: 23%">{{ subSum.conversions }}</td>
                                                <!-- <td style="width: 22%">$ {{ subSum.refigned_net_payout }}</td> -->
                                                <td style="width: 22%">
                                                    AED {{ subSum.refigned_payout }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr style="width: 100%" class="text-center">
                                                <td style="width: 21%">{{ row.advertiser }}</td>
                                                <td style="width: 24%">{{ row.coupon_code }}</td>
                                                <td style="width: 24%">AED {{ row.thumb }}</td>
                                                <!-- <td style="width: 23%">{{ row.net_conversion }}</td> -->
                                                <td style="width: 23%">{{ row.conversions }}</td>
                                                <!-- <td style="width: 22%">${{ row.refigned_net_payout }}</td> -->
                                                <td style="width: 22%">
                                                    AED {{ row.refigned_payout }}
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    </tbody>
                                </table>
                                <!-- </datatable> -->
                            </div>
                            <div
                                    v-else
                                    class="d-flex mt-3 justify-content-center alert alert-primary"
                            >
                                <b>No Records</b>
                            </div>
                        </div>
                        <div
                                class="data_grid mobile-data-grid"
                                v-if="selected_tab == 'order'"
                        >
                            <div v-if="order_arr.length > 1">

                                <datatable
                                        :columns="order_columns"
                                        :data="order_arr"
                                        class="table table-responsive vue_data_grid mt-12"
                                >
                                    <template slot-scope="{ row }">
                                        <tr class="text-center" v-show="row.coupon_code">
                                            <td>{{ row.coupon_code }}</td>
                                            <td>{{ row.advertiser }}</td>
                                            <td>{{ row.customer_type }}</td>
                                            <td>AED {{ row.payout }}</td>
                                            <td>AED {{ row.sale_amount }}</td>
                                            <td>{{ row.country }}</td>
                                            <td>{{ row.time }}</td>
                                        </tr>
                                    </template>
                                </datatable>
                                <button class="btn load-more" v-show="hasOrderData"
                                        @click="loadMore"
                                >
                                    <template>
                                        <b-overlay :show="overlay">Load More</b-overlay>
                                    </template>
                                </button>
                                <!--<div class="text-center mt-3">
                                    <v-pagination
                                            v-if="totalpages > 1"
                                            color="#151A4B"
                                            v-model="page"
                                            @input="fetchData"
                                            :length="totalpages"
                                            circle
                                    ></v-pagination>
                                </div>-->
                            </div>
                            <div
                                    v-else
                                    class="d-flex mt-3 justify-content-center alert alert-primary"
                            >
                                <b>No Records</b>
                            </div>
                        </div>
                        <div class="btn-div mt-5 mobile" style="display: none">
                            <div class="update-btn">
                                <b-button @click="advertiserModal">Updated 3 mins ago</b-button>
                                <p class="crone-alert" v-if="showCronJobMessage">
                                    Please wait your latest converstion are on progress
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                id="filter_modal"
                :no-fade="true"
                @ok="apply_filter()"
                @cancel="reset_filter"
                v-model="filterModalShow"
        >
            <template #modal-header="{ close }">
                <h4>Filter by</h4>
            </template>

            <template #default="{ hide }">
                <div class="row">
                    <div class="col-12 mt-3 d-flex"  style="padding: 0px;" :disabled="filter.date_type != 'Daterange'">
                        <date-picker
                                name="start_date"
                                input-class="form-control theme-input"
                                type="month"
                                format="MMM YYYY"
                                value-type="YYYY-MM"
                                placeholder="Select Month and Year"
                                :lang="lang"
                                :not-after="filter.end_date"
                                v-model="filter.start_date"
                                class="mt-3 col-6"
                        ></date-picker>
                    </div>
                    <!--<div class="col-2 text-center mt-3"><b>to</b></div>-->
                    <!--<div class="col-5 d-flex" :disabled="filter.date_type != 'Daterange'">-->
                    <!--<date-picker-->
                    <!--name="end_date"-->
                    <!--input-class="form-control theme-input"-->
                    <!--type="date"-->
                    <!--format="DD MMM YYYY"-->
                    <!--placeholder="To"-->
                    <!--:lang="lang"-->
                    <!--:not-before="filter.start_date"-->
                    <!--v-model="filter.end_date"-->
                    <!--&gt;</date-picker>-->
                    <!--</div>-->

                    <div v-if="selected_tab !== 'My Coupons'" class="mt-3 col-12">
                        <label for="">Advertiser</label>
                        <multiselect
                                v-model="filter.advertiser_id"
                                style="font-size: 12px"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Select Advertiser"
                                :options="advertiserList"
                                label="advertiser"
                                track-by="advertiser_id"
                                :searchable="true"
                                :allow-empty="true"
                                :multiple="true"
                        ></multiselect>
                    </div>
                    <div v-if="selected_tab !== 'My Coupons'" class="mt-3 col-12">
                        <label for="">Coupon</label>
                        <!-- <input type="text" class="form-control theme-input"> -->
                        <multiselect
                                v-model="filter.coupon_code"
                                style="font-size: 12px"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Select Coupon"
                                :options="couponList"
                                :searchable="true"
                                :allow-empty="true"
                                :multiple="true"
                        ></multiselect>
                    </div>
                    <div v-if="selected_tab == 'My Coupons'" class="mt-3 col-12">
                        <label for="">Offer Name</label>
                        <multiselect
                                v-model="filter.offer_id"
                                style="font-size: 12px"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Select Offer"
                                :options="couponsData.offers"
                                label="offer_name"
                                track-by="offer_id"
                                :searchable="true"
                                :allow-empty="true"
                                :multiple="true"
                        ></multiselect>
                    </div>
                    <div v-if="selected_tab == 'My Coupons'" class="mt-3 col-12">
                        <label for="">Coupon Status</label>
                        <!-- <input type="text" class="form-control theme-input"> -->
                        <multiselect
                                v-model="filter.coupon_status"
                                style="font-size: 12px"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Select Coupon Status"
                                :options="coupon_statuses"
                                :searchable="true"
                                :allow-empty="true"
                        ></multiselect>
                    </div>
                    <div v-if="selected_tab == 'order'" class="mt-3 col-12">
                        <label for="">Customer Type</label>
                        <!-- <input type="text" class="form-control theme-input"> -->
                        <multiselect
                                v-model="filter.customer_type"
                                style="font-size: 12px"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Select Customer Type"
                                label="name"
                                track-by="value"
                                :options="customerTypeList"
                                :searchable="true"
                                :allow-empty="true"
                        ></multiselect>
                    </div>
                    <div class="col-12">
                        <b-form-checkbox
                                id="checkbox-1"
                                v-model="isMyCoupon"
                                name="checkbox-1"
                                size="lg"
                        >
                            My Coupon
                        </b-form-checkbox>
                    </div>
                    <div
                            :class="{ hide: !seen }"
                            v-if="selected_tab == 'order'"
                            class="mt-3 col-12"
                    >
                        <label for="">Country</label>
                        <!-- <input type="text" class="form-control theme-input"> -->
                        <multiselect
                                v-model="filter.country_code"
                                style="font-size: 12px"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Select Country"
                                label="name"
                                track-by="code"
                                :options="countryList"
                                :searchable="true"
                                :allow-empty="true"
                        ></multiselect>
                    </div>
                </div>
            </template>
            <template #modal-footer="{ ok }">
                <div class="filterButtonGroup">
                    <b-button class="modal-btn" variant="dark" @click="ok()">
                        APPLY
                        <b-spinner v-if="is_loading" small/>
                    </b-button>
                    <b-button class="modal-btn" variant="light" @click="clearFilters()">
                        CLEAR
                        <b-spinner v-if="is_loading" small/>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import ApiService from "../../core/services/api.service";
    import DatePicker from "vue2-datepicker";
    import {mapGetters} from "vuex";
    import moment from "moment";
    import Loader from "@/view/content/TabLoader.vue";
    // import 'vue2-datepicker/index.css';
    import Multiselect from "vue-multiselect";
    import Button from "./vue-bootstrap/Button.vue";
    import Modal from "./ClientConversation.vue";
    // import Invoice from "../components/dashboard/invoice.vue";
    // import Swal from "sweetalert2";
    import ChartComponent from "./ChartComponent.vue";
    //import countries from "../../core/CountryCodes.json";

    export default {
        //name: "dashboard",
        components: {
            Multiselect,
            DatePicker,
            ApiService,
            Loader,
            Button,
            Modal,
            ChartComponent,
        },
        data() {
            return {
                totalconversionvariable: 0,
                loadingmore: false,
                totalpayouts: 0,
                isMyCoupon: false,
                loading: false,
                hasOrderData: true,
                date: null,
                propsFilter: {
                    advertiser_id: null,
                    coupon_code: null,
                    offer_id: null,
                    start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    end_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                    date_type: 'Daterange',
                    coupon_status: null,
                },
                overlayShow: localStorage.overlayShow ? true : false,
                offerslinks: [
                    {
                        link: "https://xd.adobe.com/view/83553353-4c48-44e0-9ce7-aac1036a69d0-d606/?fullscreen&hints=off",
                        label: "Offer Insights",
                        icon: "insight.svg",
                    },
                    {
                        link: "https://xd.adobe.com/view/be44a665-072a-43b8-854b-3836cbe74882-eb17/?fullscreen&hints=off",
                        label: "CPX Affiliate Guide- English",
                        icon: "english.svg",
                    },
                    {
                        link: "https://xd.adobe.com/view/e81c6e52-3fc5-4234-bb01-2559a2f9284f-bd4f/?fullscreen&hints=off",
                        label: "CPX Affiliate Guide- Arabic",
                        icon: "arabic.svg",
                    },
                ],
                sumDown: true,
                moment,
                sumUp: false,
                sumConversionDown: true,
                sumConversionUp: false,
                sumNetConversionDown: true,
                sumNetConversionUp: false,
                sumPayoutDown: true,
                sumPayoutUp: false,
                sumNetPayoutDown: true,
                sumNetPayoutUp: false,
                // countryList: countries,
                customerTypeList: [
                    {
                        name: "New",
                        value: "new",
                    },
                    {
                        name: "Returning",
                        value: "returning",
                    },
                ],
                filterApplied: false,
                loadcoupons: false,
                showCronJobMessage: false,
                total_payout_title: "Current Month payout",
                total_conversions_title: "Current Month Orders",
                overlay: false,
                totalNetOrders: null,
                // hrsago: "0",
                timerCount: 0,
                timeCountRefresh: 0,
                totalpages: 0,
                page: 1,
                couponPage: 1,
                couponPagePagination: 1,
                orderPagePagination: 1,
                lang: "en",
                affiliate_id: null,
                total_conversions: 0,
                total_payout: 0,
                dashboard: {
                    last_refreshed: null,
                    approved_conver: 0,
                    pending_conver: 0,
                    total_conversions: 0,
                    total_payout: 0,
                },
                customerDetails: {},
                countryDetails: {},
                selected_tab: "My Coupons",
                all_data: [],
                exportFile: true,
                couponsData: [],
                coupons_columns: [
                    {
                        label: "ADVERTISER",
                        field: "offer_name",
                        headerClass: "light_gray",
                        sortable: false,
                    },
                    {
                        label: "COUPON",
                        field: "coupon",
                        headerClass: "light_gray",
                        sortable: false,
                    },
                    {
                        label: "COUPON OFFERING",
                        field: "coupon_offering",
                        headerClass: "light_gray desk",
                        sortable: false,
                    },
                    {
                        label: "STATUS",
                        field: "status",
                        headerClass: "light_gray text-center",
                        sortable: true,
                    },
                ],
                refignedSummaryTabs: [],
                summarytab: [],
                order_columns: [
                    {
                        label: "Coupon",
                        field: "coupon_code",
                        headerClass: "light_gray",
                        sortable: false,
                    },
                    {
                        label: "Advertiser",
                        field: "advertiser",
                        headerClass: "light_gray ",
                        sortable: false,
                    },
                    {
                        label: " Customer Type",
                        field: "customer_type",
                        headerClass: "light_gray ",
                        sortable: false,
                    },
                    {
                        label: "Payout",
                        field: "payout",
                        headerClass: "light_gray text-center",
                        sortable: false,
                    },
                    {
                        label: "Sale Amount",
                        field: "sale_amount",
                        headerClass: "light_gray text-center",
                        sortable: false,
                    },
                    {
                        label: "Country",
                        field: "country",
                        headerClass: "light_gray text-center",
                        sortable: false,
                    },
                    {
                        label: "Order Date",
                        field: "time",
                        headerClass: "light_gray text-center",
                        sortable: false,
                    },
                ],
                order_arr: [],
                filter: {
                    advertiser_id: null,
                    coupon_code: null,
                    offer_id: null,
                    start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    end_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                    date_type: 'Daterange',
                    coupon_status: null,
                },
                adver_array: [],
                coupon_array: [],
                is_loading: false,
                last_refreshed: localStorage.getItem("lastSeen")
                    ? new Date(localStorage.getItem("lastSeen"))
                    : new Date(),
                advertiserList: [],
                couponList: [],
                filterModalShow: false,
                advertiserModalShow: false,
                coupon_statuses: ["Get All", "Active", "In-Active"],
            };
        },
        mounted() {
            $('.my-2').hide();
            if (!localStorage.totalNotification) localStorage.totalNotification = [];
            if (!localStorage.overlayShow) {
                localStorage.overlayShow = true;
            } else {
                this.overlayShow = localStorage.overlayShow;
            }
            const currentDate = moment();
            this.filter.start_date = new Date(
                currentDate.startOf("month").format("YYYY-MM-DD")
            );
            this.filter.end_date = new Date(
                currentDate.endOf("month").format("YYYY-MM-DD")
            );
            setTimeout(() => {
                this.get_coupon_advertiaer_list();
                // this.$refs.childCompo.getData();
            }, 8000);

            // this.get_coupon_advertiaer_list();
            this.fetchCouponsData();
            // this.fetchData();
            //this.loadScrollCoupons();
            this.$eventBus.$on("downloadFile", () => {
                this.exportFile = false;
                this.DownloadFileAndFetch();
            });
            this.$eventBus.$on("filerModal", () => {
                this.filterModalShow = !this.filterModalShow;
            });


        },
        methods: {
            getLastDayOfMonth(dateInput) {
                // Create a Date object from the input date
                const date = new Date(dateInput);

                // Get the year and month from the date
                const year = date.getFullYear();
                const month = date.getMonth(); // 0-indexed, so January is 0

                // Create a new date object for the first day of the next month
                // and then set the date to 0 to get the last day of the current month
                const lastDay = new Date(year, month + 1, 0);

                // Format the date in YYYY-MM-DD format
                const formattedDate = lastDay.toISOString().split('T')[0];

                return formattedDate; // Returns the last day in YYYY-MM-DD format
            },
            async fetchCurrentMonthData() {
                this.loading = true; //the loading begin
                this.loadingmore = false;
                if (this.filter.start_date && this.filter.end_date) {
                    this.filter.date_type = "Daterange";
                }
                if (!localStorage.getItem("lastSeen")) {
                    localStorage.setItem("lastSeen", new Date());
                }
                let dataToPost = {
                    timeperiod: this.filter.date_type,
                    affiliate_id: this.affiliate_id
                };
                if (this.filter.start_date) {
                    dataToPost["startdate"] = this.formatDate(this.filter.start_date);
                    var startDate = moment(dataToPost["startdate"]); // Change the date as needed
                    dataToPost["enddate"] = startDate.endOf('month').format("YYYY-MM-DD");
                    this.filter.end_date = dataToPost["enddate"];
                }
                // if (this.filter.end_date) {
                //     dataToPost["enddate"] = this.formatDate(this.filter.end_date);
                // }
                if (this.filter.date_type.trim()) {
                    dataToPost["timeperiod"] = this.filter.date_type;
                }
                if (this.filter.customer_type) {
                    dataToPost["customer_type"] = this.filter.customer_type.name;
                }
                if (this.filter.coupon_code && this.filter.coupon_code.length) {
                    dataToPost["coupon_code"] = this.filter.coupon_code;
                }

                if (this.filter.advertiser_id && this.filter.advertiser_id.length) {
                    dataToPost["advertiser_id"] = this.filter.advertiser_id.map(
                        (x) => x.advertiser_id
                    );
                } else {
                    dataToPost["advertiser_id"] = []
                }

                await ApiService.post("/current_month_data", dataToPost)
                    .then((response) => {
                        this.loading = false; //the loading begin
                        this.totalconversionvariable = parseFloat(response.data.data.total_orders).toFixed(0);
                        this.totalpayouts = parseFloat(response.data.data.total_payout).toFixed(0);
                        // this.customSort();
                    });

            },
            updateTotalConversionVariable(newValue) {
                this.totalconversionvariable = newValue;
            },
            updateTotalPayouts(newvalue) {
                this.totalpayouts = newvalue;
            },
            handleTabChange(e) {
                this.selected_tab = e;
                console.log(e);
                if (e == "My Coupons" || e == "offers") {
                    $('.my-2').hide();
                } else {
                    $('.my-2').show();
                }

            },
            advertiserModal() {
                this.advertiserModalShow = !this.advertiserModalShow;
            },
            customSort() {
                this.couponsData.coupons.sort((a, b) => a.status.localeCompare(b.status));
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            date_calc(type) {
                this.filter.date_type = type;
                var date = new Date();
                if (type == "Today") {
                    this.filter.start_date = new Date();
                    this.filter.end_date = new Date();
                    return;
                } else if (type == "Yesterday") {
                    let startdate = new Date(date.setDate(date.getDate() - 1));
                    let enddate = new Date();
                    this.filter.start_date = startdate;
                    this.filter.end_date = enddate;
                    return;
                } else if (type == "Last7days") {
                    let startDate = this.getMondayForWeek(date);
                    let toDate = this.addDays(startDate, 6);
                    this.filter.start_date = startDate;
                    this.filter.end_date = toDate;
                    return;
                } else if (type == "this_month") {
                    var date = new Date();
                    let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                    this.filter.start_date = firstDayOfMonth;
                    this.filter.end_date = new Date();
                    return;
                } else if (type == "Last30days") {
                    let firstDayOfMonth = new Date(
                        date.getFullYear() - (date.getMonth() > 0 ? 0 : 1),
                        (date.getMonth() - 1 + 12) % 12,
                        1
                    );
                    let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 0);
                    this.filter.start_date = firstDayOfMonth;
                    this.filter.end_date = lastDayOfMonth;
                    return;
                } else if (type == "Daterange") {
                    this.filter.start_date = null;
                    this.filter.end_date = null;
                    return;
                }
            },
            reset_filter() {
                let date = new Date();
                this.filter.start_date = new Date(date.getFullYear(), date.getMonth(), 1);
                this.filter.end_date = new Date();
                this.filter.offer_id = null;
                this.filter.coupon_status = null;
                this.filter.coupon_code = "";
                this.filter.advertiser_id = "";
                this.filterApplied = false;
                this.isMyCoupon = false;
                this.apply_filter();
            },
            apply_filter() {
                this.filterApplied = true;
                this.loading = true;
                this.is_loading = true;
                this.over = true;
                this.order_arr = [];
                if (this.filter.start_date) {
                    var startDate = this.formatDate(this.filter.start_date);
                    var startDate = moment(startDate); // Change the date as needed
                    this.filter.end_date = startDate.endOf('month').format("YYYY-MM-DD");
                }

                this.propsFilter = {...this.filter}
                let date = new Date();
                let Tstart = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    1
                ).toLocaleDateString();
                this.fetchWholeData();
                this.fetchCurrentMonthData();
                // this.loading = false;
                // this.is_loading = false;
                let Tend = new Date().toLocaleDateString();
                if (this.filter.start_date != null) {
                    if (
                        this.filter.start_date.toLocaleDateString() == Tstart &&
                        this.filter.end_date.toLocaleDateString() == Tend
                    ) {
                        this.total_payout_title = "MTD total payout";
                        this.total_conversions_title = "MTD total conversions";
                    } else {
                        this.total_payout_title = "total payout";
                        this.total_conversions_title = "total conversions";
                    }

                } else {
                    this.total_payout_title = "total payout";
                    this.total_conversions_title = "total conversions";
                }
                //this.isMyCoupon = true;
                // if (this.selected_tab == 'My Coupons') {
                //     this.FilterCouponsData(1);
                // } else if (this.selected_tab == 'summary' || this.selected_tab == 'order') {
                //     this.fetchData()
                // }
            },
            clearFilters() {
                this.filter = {
                    advertiser_id: null,
                    coupon_code: null,
                    offer_id: null,
                    start_date: null,
                    end_date: null,
                    date_type: null,
                    coupon_status: null,
                };
            },
            exportTableToCSV(filename) {
                var csv = [];
                var rows = document.querySelectorAll("table tr");
                for (var i = 0; i < rows.length; i++) {
                    var row = [],
                        cols = rows[i].querySelectorAll("td, th");
                    for (var j = 0; j < cols.length; j++) {
                        if (cols[j].innerText.includes(",")) {
                            row.push("@" + cols[j].innerText + "@");
                        } else row.push(cols[j].innerText);
                    }
                    csv.push(row.join(","));
                }
                csv = csv.join("\n");
                csv = csv.replaceAll("@", '"');
                // Download CSV file
                this.downloadCSV(csv, filename);
            },
            DownloadFileAndFetch() {
                if (this.selected_tab == "My Coupons") {
                    this.fetchWholeCouponsData();
                } else if (this.selected_tab != "My Coupons" && this.selected_tab != "dashboard") {
                    this.fetchWholeData();
                    setTimeout(() => {
                        this.exportTableToCSV("Performance_Overview.csv");
                    }, 2500);
                    this.exportFile = true;
                } else {
                    alert("Oops! The overview CSV export is not supported. To export, please navigate to Summary.");
                    this.exportFile = false;
                }


            },
            downloadCSV(csv, filename) {
                var csvFile;
                var downloadLink;

                // CSV file
                csvFile = new Blob([csv], {type: "text/pdf"});

                // Download link
                downloadLink = document.createElement("a");

                // File name
                downloadLink.download = filename;

                // Create a link to the file
                downloadLink.href = window.URL.createObjectURL(csvFile);

                // Hide download link
                downloadLink.style.display = "none";

                // Add the link to DOM
                document.body.appendChild(downloadLink);

                // Click download link
                downloadLink.click();
            },
            loadMore() {
                if (this.orderPagePagination > 60) return;
                this.orderPagePagination = this.orderPagePagination + 1;
                this.fetchWholeData();

            },
            // Fetch the Whole Data
            fetchWholeData() {
                this.overlay = true;
                console.log('filter'+this.filter.length);
                if (this.filter.start_date && this.filter.end_date) {
                    this.filter.date_type = "Daterange";
                }
                if (!localStorage.getItem("lastSeen")) {
                    localStorage.setItem("lastSeen", new Date());
                }
                let dataToPost = {
                    timeperiod: this.filter.date_type,
                    affiliate_id: this.affiliate_id,
                    page: this.orderPagePagination,
                    pagesize: 25,
                };
                if (this.filter.start_date) {
                    dataToPost["startdate"] = this.formatDate(this.filter.start_date);
                    var startDate = moment(dataToPost["startdate"]); // Change the date as needed
                    dataToPost["enddate"] = startDate.endOf('month').format("YYYY-MM-DD");
                    this.filter.end_date = dataToPost["enddate"];
                }
                // if (this.filter.end_date) {
                //     dataToPost["enddate"] = this.formatDate(this.filter.end_date);
                // }
                if (this.filter.date_type.trim()) {
                    dataToPost["timeperiod"] = this.filter.date_type;
                }
                if (this.filter.coupon_code && this.filter.coupon_code.length) {
                    dataToPost["coupon_code"] = this.filter.coupon_code;
                }

                if (this.filter.advertiser_id && this.filter.advertiser_id.length) {
                    dataToPost["advertiser_id"] = this.filter.advertiser_id.map(
                        (x) => x.advertiser_id
                    );
                } else {
                    dataToPost["advertiser_id"] = []
                }
                console.log('order_length2' + this.order_arr.length);

                ApiService.post('/conversions_report_dashboard', dataToPost)
                    .then(response => {

                            this.dashboard.total_conversions = response.data.data.totals.total_conversions;
                            this.dashboard.pending_conver = response.data.data.totals.pending_conversions;
                            this.dashboard.total_payout = Number(response.data.data.totals.total_payout).toFixed(0);
                            this.dashboard.pending_payout = response.data.data.totals.pending_payout;
                            this.totalpages = response.data.data.totals.total_page;
                            if (this.orderPagePagination == this.totalpages) {
                                this.hasOrderData = false;
                            }
                            // set row Data for grid
                            let row = response.data.data.All_Conversions;

                            this.summarytab = response.data.data.Summary_Conversions;
                            this.refignedSummaryTabs = this.summarytab.map((tab) => {
                                return Object.assign({}, tab, {
                                    thumb: tab.sale_amount,
                                    refigned_payout: tab.payout,
                                    refigned_net_payout: tab.net_payout,
                                });
                            });
                            this.summarytab = this.refignedSummaryTabs;
                            let uniqueSummary = Object.values(
                                this.summarytab.reduce((r, o) => {
                                    r[o.advertiser] = r[o.advertiser] || o;
                                    return r;
                                }, {})
                            );
                            this.summarytab = uniqueSummary.map((item, i) => {
                                return Object.assign({}, item, {
                                    showData: false,
                                    multipleData: this.summarytab.filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    }),
                                    conversions: this.summarytab
                                        .filter(function (curr) {
                                            if (curr.advertiser === item.advertiser) {
                                                return curr;
                                            }
                                        })
                                        .reduce((acc, item) => {
                                            return Number(acc) + Number(item.conversions);
                                        }, 0),
                                    thumb: this.summarytab
                                        .filter(function (curr) {
                                            if (curr.advertiser === item.advertiser) {
                                                return curr;
                                            }
                                        })
                                        .reduce((acc, item) => {
                                            return (
                                                parseFloat(acc) + parseFloat(item.thumb.replace(/,/g, ""))
                                            );
                                        }, 0),
                                    refigned_payout: this.summarytab
                                        .filter(function (curr) {
                                            if (curr.advertiser === item.advertiser) {
                                                return curr;
                                            }
                                        })
                                        .reduce((acc, item) => {
                                            return (
                                                parseFloat(acc) +
                                                parseFloat(item.refigned_payout.replace(/,/g, ""))
                                            );
                                        }, 0),
                                    refigned_net_payout: this.summarytab
                                        .filter(function (curr) {
                                            if (curr.advertiser === item.advertiser) {
                                                return curr;
                                            }
                                        })
                                        .reduce((acc, item) => {
                                            return (
                                                parseFloat(acc) +
                                                parseFloat(item.refigned_net_payout.replace(/,/g, ""))
                                            );
                                        }, 0),
                                });
                            });
                            // this.summarytab.sale_amount = this.summarytab.sale_amount.replace(
                            //   /\B(?=(\d{3})+(?!\d))/g,
                            //   ","
                            // );
                            let coupon_arr = [];
                            let adver_arr = [];
                            row.forEach((r, k) => {
                                let arr_conv = [];
                                coupon_arr.push(r.coupon_code);
                                adver_arr.push(r.offer_name);
                                row.forEach((element) => {
                                    if (r.coupon_code == element.coupon_code) {
                                        arr_conv.push(parseFloat(element.payout));
                                    }
                                });
                                row[k].conversions = arr_conv.length;
                                // row[k].pay_out = row[k].payout;
                                // arr_conv = [];
                            });

                            this.order_arr = [...this.order_arr, ...row];
                            this.order_arr.push(row.map((x) => {
                                return {
                                    coupon_code: x.coupon_code,
                                    advertiser: x.advertiser,
                                    customer_type: x.customer_type,
                                    payout: x.payout,
                                    sale_amount: x.sale_amount,
                                    country: x.country,
                                    Order_date: x.time ? x.time : "-",
                                };
                            }));
                            this.all_data = row; // All Data for sorting by date filter
                            this.loadingmore = false;
                            this.overlay = false;
                            this.loading = false;
                            this.is_loading = false;
                            // Drop Down Data
                            //   Advertiser Data
                            // adver_arr = [...new Set(adver_arr)];
                            //
                            // this.adver_array = adver_arr;
                            // // Coupon Array Data
                            // this.coupon_array = [...new Set(coupon_arr)];
                        }
                    )
                    .catch(error => {
                        console.log(error);
                    });

            },
            //fetch coupons
            async fetchCouponsData() {
                this.is_loading = true;
                let dataToPost = {
                    affiliate_id: this.affiliate_id,
                    page: this.couponPagePagination,
                    pagesize: 9999999,
                    status: this.filter.coupon_status,
                };
                await ApiService.post("/my-coupons", dataToPost)
                    .then((response) => {
                        // console.log(response);
                        response.data.data.coupons.forEach((e) => {
                            // e.coupon_offering = "#ANC  #JDJS #DJD" + e.coupon_offering;
                            if (e.coupon_offering != null) {
                                e.coupon_offering = this.removeHastag(e.coupon_offering);
                            }
                        });
                        this.couponsData = response.data.data;
                        this.customSort();
                    })
                    .catch((error) => {
                        if (error.response.status == 400) {
                            localStorage.clear();
                            localStorage.removeItem("affiliate_id");
                            window.location.reload();
                            // Swal.fire({
                            //   imageUrl: "media/images/logout-emoji.png",
                            //   imageWidth: 165,
                            //   imageHeight: 123,
                            //   title: "Your Session is expired.",
                            //   timer: 1500,
                            //   timerProgressBar: true,
                            //   showConfirmButton: false,
                            // })
                            // setTimeout(() => {
                            //   window.location.reload();
                            // }, 1500);
                        }
                        throw new Error(`[KT] ApiService ${error}`);
                    })
                    .finally(() => {
                        this.is_loading = false;
                    })
            },
            loadScrollCoupons() {
                window.onwheel = () => {

                    let bottomOfWindow =
                        ((document.documentElement.scrollTop + window.innerHeight) /
                            document.documentElement.scrollHeight) *
                        100;
                    if (
                        bottomOfWindow > 95 &&
                        this.selected_tab == "coupons" &&
                        this.couponsData.totals.total_page > this.couponPagePagination
                    ) {
                        this.couponPagePagination += 1;
                        this.is_loading = true;
                        let dataToPost = {
                            affiliate_id: this.affiliate_id,
                            page: this.couponPagePagination,
                            pagesize: 15,
                            status: this.filter.coupon_status,
                        };
                        ApiService.post("/my-coupons", dataToPost)
                            .then((response) => {
                                response.data.data.coupons.forEach((e) => {
                                    if (e.coupon_offering != null) {
                                        e.coupon_offering = this.removeHastag(e.coupon_offering);
                                    }
                                });
                                var allData = this.couponsData.coupons.concat(
                                    response.data.data.coupons
                                );
                                var inactiveData = allData.filter(function (r) {
                                    return r.status == "In-Active";
                                });
                                var activeData = allData.filter(function (r) {
                                    return r.status == "Active";
                                });
                                inactiveData.sort(function (a, b) {
                                    return (
                                        new Date(b.assignment_date) - new Date(a.assignment_date)
                                    );
                                });
                                activeData.sort(function (a, b) {
                                    return (
                                        new Date(b.assignment_date) - new Date(a.assignment_date)
                                    );
                                });
                                this.couponsData.coupons = activeData.concat(inactiveData);
                            })
                            .catch((error) => {
                                this.is_loading = false;
                                if (error.response.status == 400) {
                                    localStorage.clear();
                                    localStorage.removeItem("affiliate_id");
                                    window.location.reload();
                                    // Swal.fire({
                                    //   imageUrl: "media/images/logout-emoji.png",
                                    //   imageWidth: 165,
                                    //   imageHeight: 123,
                                    //   title: "Your Session is expired.",
                                    //   timer: 1500,
                                    //   timerProgressBar: true,
                                    //   showConfirmButton: false,
                                    // })
                                    // setTimeout(() => {
                                    //   window.location.reload();
                                    // }, 1500);
                                }
                                throw new Error(`[KT] ApiService ${error}`);
                            });
                        this.is_loading = false;
                    }
                };
            },
            removeHastag(text) {
                const regex = /#+([a-zA-Z_]+)/gi;
                const replaced = text.replace(regex, (value) => "");
                return replaced;
            },
            FilterCouponsData(page, page_size) {
                if (this.filter.start_date && this.filter.end_date) {
                    this.filter.date_type = "Daterange";
                } else {
                    this.filter.date_type = "";
                }
                if (!localStorage.getItem("lastSeen")) {
                    localStorage.setItem("lastSeen", new Date());
                }
                this.is_loading = true;
                let dataToPost = {
                    affiliate_id: this.affiliate_id,
                    page: page ? page : this.couponPagePagination,
                    pagesize: 9999999,
                };
                if (this.isMyCoupon == true) {
                    if (this.filter.offer_id) {
                        dataToPost["offer_id"] = this.filter.offer_id.map((x) => x.offer_id);
                    }
                    if (this.filter.coupon_status) {
                        dataToPost["status"] = this.filter.coupon_status;
                    }
                    if (this.filter.start_date) {
                        dataToPost["startdate"] = this.formatDate(this.filter.start_date);
                        var startDate = moment(dataToPost["startdate"]); // Change the date as needed
                        dataToPost["enddate"] = startDate.endOf('month').format("YYYY-MM-DD");
                        this.filter.end_date = dataToPost["enddate"];
                    }
                    /*if (this.filter.end_date) {
                        dataToPost["enddate"] = this.formatDate(this.filter.end_date);
                    }*/
                    if (this.filter.date_type.trim()) {
                        dataToPost["timeperiod"] = this.filter.date_type;
                    }
                    if (this.filter.coupon_code && this.filter.coupon_code.length) {
                        dataToPost["coupon_code"] = this.filter.coupon_code;
                    }

                    if (this.filter.advertiser_id && this.filter.advertiser_id.length) {
                        dataToPost["advertiser_id"] = this.filter.advertiser_id.map(
                            (x) => x.advertiser_id
                        );
                    } else {
                        dataToPost["advertiser_id"] = []
                    }
                }
                this.loadcoupons = true;
                ApiService.post("/my-coupons", dataToPost)
                    .then((response) => {
                        response.data.data.coupons.forEach((e) => {
                            // e.coupon_offering = "#ANC  #JDJS #DJD" + e.coupon_offering;
                            if (e.coupon_offering != null) {
                                e.coupon_offering = this.removeHastag(e.coupon_offering);
                            }
                        });
                        this.couponsData = response.data.data;
                        this.loadcoupons = false;
                    })
                    .catch((error) => {
                        this.is_loading = false;
                        if (error.response.status == 400) {
                            localStorage.clear();
                            localStorage.removeItem("affiliate_id");
                            window.location.reload();
                            // Swal.fire({
                            //   imageUrl: "media/images/logout-emoji.png",
                            //   imageWidth: 165,
                            //   imageHeight: 123,
                            //   title: "Your Session is expired.",
                            //   timer: 1500,
                            //   timerProgressBar: true,
                            //   showConfirmButton: false,
                            // })
                            // setTimeout(() => {
                            //   window.location.reload();
                            // }, 1500);
                        }
                        throw new Error(`[KT] ApiService ${error}`);
                    });
                this.is_loading = false;
            },
            fetchWholeCouponsData() {
                // setTimeout(() => {
                this.FilterCouponsData(1, 9999999);
                // // }, 1500);
                // setTimeout(() => {
                this.exportTableToCSV("Coupons_Overview.csv");
                //   console.log("5555555555555555");
                // }, 3500);

                // setTimeout(() => {
                //   this.exportTableToCSV("Coupons_Overview.csv");
                // }, 5000);

                setTimeout(() => {
                    this.FilterCouponsData(1, 9999999);
                }, 4200);
            },

            // Fetch whole data
            fetchData() {
                console.log('in');
                if (this.filter.start_date && this.filter.end_date) {
                    this.filter.date_type = "Daterange";
                } else {
                    this.filter.date_type = "";
                }
                if (!localStorage.getItem("lastSeen")) {
                    localStorage.setItem("lastSeen", new Date());
                }
                let dataToPost = {
                    timeperiod: this.filter.date_type,
                    // startdate: this.filter.start_date
                    //   ? this.formatDate(this.filter.start_date)
                    //   : "",
                    // enddate: this.filter.end_date
                    //   ? this.formatDate(this.filter.end_date)
                    //   : "",
                    affiliate_id: this.affiliate_id,
                    page: this.page,
                    pagesize: 15,
                };
                if (this.selected_tab == "coupons") {
                    dataToPost["status"] = this.filter.coupon_status;
                }
                if (this.filter.start_date) {
                    dataToPost["startdate"] = this.formatDate(this.filter.start_date);
                    var startDate = moment(dataToPost["startdate"]); // Change the date as needed
                    dataToPost["enddate"] = startDate.endOf('month').format("YYYY-MM-DD");
                    this.filter.end_date = dataToPost["enddate"];
                }
                /*if (this.filter.end_date) {
                    dataToPost["enddate"] = this.formatDate(this.filter.end_date);
                }*/
                if (this.filter.date_type.trim()) {
                    dataToPost["timeperiod"] = this.filter.date_type;
                }
                if (this.filter.coupon_code && this.filter.coupon_code.length) {
                    dataToPost["coupon_code"] = this.filter.coupon_code;
                }
                if (this.filter.customer_type) {
                    dataToPost["customer_type"] = this.filter.customer_type.name;
                }
                if (this.filter.country_code) {
                    dataToPost["country_code"] = this.filter.country_code;
                }
                if (this.filter.customer_type) {
                    dataToPost["customer_type"] = this.filter.customer_type.name;
                }
                if (this.filter.country_code) {
                    dataToPost["country_code"] = this.filter.country_code;
                }
                if (this.filter.advertiser_id && this.filter.advertiser_id.length) {
                    dataToPost["advertiser_id"] = this.filter.advertiser_id.map(
                        (x) => x.advertiser_id
                    );
                } else {
                    dataToPost["advertiser_id"] = []
                }
                this.is_loading = true;
                ApiService.post("/conversions_report_dashboard?", dataToPost)
                    .then((response) => {

                        this.dashboard.total_conversions =
                            response.data.data.totals.total_conversions;
                        this.dashboard.pending_conver =
                            response.data.data.totals.pending_conversions;
                        this.dashboard.total_payout = Number(
                            response.data.data.totals.total_payout
                        ).toFixed(0);
                        this.dashboard.pending_payout =
                            response.data.data.totals.pending_payout;
                        this.totalpages = response.data.data.totals.total_page;

                        // set row Data for grid
                        let row = response.data.data.All_Conversions;
                        this.summarytab = response.data.data.Summary_Conversions;
                        this.refignedSummaryTabs = this.summarytab.map((tab) => {
                            return Object.assign({}, tab, {
                                thumb: tab.sale_amount
                                    ? tab.sale_amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "",
                                refigned_payout: tab.payout
                                    ? tab.payout.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "",
                                refigned_net_payout: tab.net_payout
                                    ? tab.net_payout.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "",
                            });
                        });

                        this.summarytab = this.refignedSummaryTabs;
                        let uniqueSummary = Object.values(
                            this.summarytab.reduce((r, o) => {
                                r[o.advertiser] = r[o.advertiser] || o;
                                return r;
                            }, {})
                        );
                        this.summarytab = uniqueSummary.map((item, i) => {
                            return Object.assign({}, item, {
                                showData: false,
                                multipleData: this.summarytab.filter(function (curr) {
                                    if (curr.advertiser === item.advertiser) {
                                        return curr;
                                    }
                                }),
                                conversions: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return acc + item.conversions;
                                    }, 0),
                                thumb: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return (
                                            parseFloat(acc) + parseFloat(item.thumb.replace(/,/g, ""))
                                        );
                                    }, 0),
                                refigned_payout: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return (
                                            parseFloat(acc) +
                                            parseFloat(item.refigned_payout.replace(/,/g, ""))
                                        );
                                    }, 0),
                                refigned_net_payout: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return (
                                            parseFloat(acc) +
                                            parseFloat(item.refigned_net_payout.replace(/,/g, ""))
                                        );
                                    }, 0),
                            });
                        });
                        let coupon_arr = [];
                        let adver_arr = [];
                        row.forEach((r, k) => {
                            let arr_conv = [];
                            coupon_arr.push(r.coupon_code);
                            adver_arr.push(r.offer_name);
                            row.forEach((element) => {
                                if (r.coupon_code == element.coupon_code) {
                                    arr_conv.push(parseFloat(element.payout));
                                }
                            });
                            row[k].conversions = arr_conv.length;
                            // row[k].pay_out = row[k].payout;
                        });

                        this.order_arr = row; // Order Data
                        console.log('orders 2' + order_arr.length);

                        this.order_arr = row.map((x) => {
                            return {
                                coupon_code: x.coupon_code,
                                advertiser: x.advertiser,
                                customer_type: x.customer_type,
                                payout: x.payout,
                                sale_amount: x.sale_amount,
                                country: x.country,
                                Order_date: x.time ? x.time : "-",
                            };
                        });
                        this.all_data = row; // All Data for sorting by date filter
                        this.is_loading = false;
                        // Drop Down Data
                        //   Advertiser Data
                        adver_arr = [...new Set(adver_arr)];
                        // let a_arr= [];
                        //     adver_arr.forEach(element => {
                        //       let in_arr = element.split("|")
                        //           in_arr.forEach(ele => {
                        //               a_arr.push(ele)
                        //           });
                        //     });
                        // this.adver_array = [... new Set(a_arr)];
                        this.adver_array = adver_arr;
                        // Coupon Array Data
                        this.coupon_array = [...new Set(coupon_arr)];
                    })
                    .catch((error) => {
                        this.is_loading = false;
                        if (error.response.status == 400) {
                            localStorage.clear();
                            localStorage.removeItem("affiliate_id");
                            window.location.reload();
                            // Swal.fire({
                            //   imageUrl: "media/images/logout-emoji.png",
                            //   imageWidth: 165,
                            //   imageHeight: 123,
                            //   title: "Your Session is expired.",
                            //   timer: 1500,
                            //   timerProgressBar: true,
                            //   showConfirmButton: false,
                            // })
                            // setTimeout(() => {
                            //   window.location.reload();
                            // }, 1500);
                        }
                        throw new Error(`[KT] ApiService ${error}`);
                    });
            },
            refreshed_data() {
                location.reload();
            },
            sortBy(isUp, isDown) {
                if (isUp) {
                    this.summarytab.sort((a, b) => {
                        return b.thumb - a.thumb;
                    });
                    this.sumUp = false;
                    this.sumDown = true;
                } else if (isDown) {
                    this.summarytab.sort((a, b) => {
                        return a.thumb - b.thumb;
                    });
                    this.sumDown = false;
                    this.sumUp = true;
                }
                return this.summarytab;
            },
            sortByNetConversion(isUp, isDown) {
                if (isUp) {
                    this.summarytab.sort((a, b) => {
                        return b.conversions - a.conversions;
                    });
                    this.sumNetConversionUp = false;
                    this.sumNetConversionDown = true;
                } else if (isDown) {
                    this.summarytab.sort((a, b) => {
                        return a.conversions - b.conversions;
                    });
                    this.sumNetConversionDown = false;
                    this.sumNetConversionUp = true;
                }
                return this.summarytab;
            },
            sortByConversions(isUp, isDown) {
                if (isUp) {
                    this.summarytab.sort((a, b) => {
                        return b.conversions - a.conversions;
                    });
                    this.sumConversionUp = false;
                    this.sumConversionDown = true;
                } else if (isDown) {
                    this.summarytab.sort((a, b) => {
                        return a.conversions - b.conversions;
                    });
                    this.sumConversionDown = false;
                    this.sumConversionUp = true;
                }
                return this.summarytab;
            },
            SortByPayout(isUp, isDown) {
                if (isUp) {
                    this.summarytab.sort((a, b) => {
                        return b.refigned_payout - a.refigned_payout;
                    });
                    this.sumPayoutUp = false;
                    this.sumPayoutDown = true;
                } else if (isDown) {
                    this.summarytab.sort((a, b) => {
                        return a.refigned_payout - b.refigned_payout;
                    });
                    this.sumPayoutDown = false;
                    this.sumPayoutUp = true;
                }
                return this.summarytab;
            },
            SortByNetPayout(isUp, isDown) {
                if (isUp) {
                    this.summarytab.sort((a, b) => {
                        return b.refigned_net_payout - a.refigned_net_payout;
                    });
                    this.sumNetPayoutUp = false;
                    this.sumNetPayoutDown = true;
                } else if (isDown) {
                    this.summarytab.sort((a, b) => {
                        return a.refigned_net_payout - b.refigned_net_payout;
                    });
                    this.sumNetPayoutDown = false;
                    this.sumNetPayoutUp = true;
                }
                return this.summarytab;
            },


            /* util function to get date difference in minute, hours or seconds */
            getMondayForWeek(d) {
                d = new Date(d);
                var day = d.getDay(),
                    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
                return new Date(d.setDate(diff - 7));
            },
            addDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
            },
            formatDate(d) {
                return dateFormat(d, "yyyy-mm-dd");
            },
            formatTime(d) {
                return dateFormat(d, "isoTime");
            },
            formatDateTime(d) {
                let date = this.formatDate(d);
                let time = this.formatTime(d);
                // return dateFormat(d, "isoDateTime");
                return date + " " + time;
            },
            get_coupon_advertiaer_list() {
                ApiService.post("/advertiser_coupon_list", {
                    affiliate_id: this.affiliate_id,
                })
                    .then((response) => {
                        this.advertiserList = response.data.data.advertiser;
                        this.couponList = response.data.data.coupons;
                    })
                    .catch((error) => {
                        if (error.response.status == 400) {
                            localStorage.clear();
                            localStorage.removeItem("affiliate_id");
                            window.location.reload();
                            // Swal.fire({
                            //   imageUrl: "media/images/logout-emoji.png",
                            //   imageWidth: 165,
                            //   imageHeight: 123,
                            //   title: "Your Session is expired.",
                            //   timer: 1500,
                            //   timerProgressBar: true,
                            //   showConfirmButton: false,
                            // })
                            // setTimeout(() => {
                            //   window.location.reload();
                            // }, 1500);
                        }
                        throw new Error(`[KT] ApiService ${error}`);
                    });
            },
            getNextOrders() {
                console.log(this.orderPagePagination);
                this.orderPagePagination += 1;
                // this.is_loading = true;

                if (this.filter.start_date && this.filter.end_date) {
                    this.filter.date_type = "Daterange";
                }
                if (!localStorage.getItem("lastSeen")) {
                    localStorage.setItem("lastSeen", new Date());
                }
                let dataToPost = {
                    timeperiod: this.filter.date_type,
                    affiliate_id: this.affiliate_id,
                    page: this.orderPagePagination,
                    pagesize: 25,
                };
                if (this.filter.start_date) {
                    dataToPost["startdate"] = this.formatDate(this.filter.start_date);
                    var startDate = moment(dataToPost["startdate"]); // Change the date as needed
                    dataToPost["enddate"] = startDate.endOf('month').format("YYYY-MM-DD");
                    this.filter.end_date = dataToPost["enddate"];
                }
                /*if (this.filter.end_date) {
                    dataToPost["enddate"] = this.formatDate(this.filter.end_date);
                }*/
                if (this.filter.date_type.trim()) {
                    dataToPost["timeperiod"] = this.filter.date_type;
                }
                if (this.filter.coupon_code && this.filter.coupon_code.length) {
                    dataToPost["coupon_code"] = this.filter.coupon_code;
                }

                if (this.filter.advertiser_id && this.filter.advertiser_id.length) {
                    dataToPost["advertiser_id"] = this.filter.advertiser_id.map(
                        (x) => x.advertiser_id
                    );
                } else {
                    dataToPost["advertiser_id"] = []
                }


                ApiService.post('/conversions_report_dashboard', dataToPost)
                    .then(response => {
                        this.dashboard.total_conversions = response.data.data.totals.total_conversions;
                        this.dashboard.pending_conver = response.data.data.totals.pending_conversions;
                        this.dashboard.total_payout = Number(response.data.data.totals.total_payout).toFixed(0);
                        this.dashboard.pending_payout = response.data.data.totals.pending_payout;
                        this.totalpages = response.data.data.totals.total_page;

                        // set row Data for grid
                        let row = response.data.data.All_Conversions;
                        this.summarytab = response.data.data.Summary_Conversions;
                        this.refignedSummaryTabs = this.summarytab.map((tab) => {
                            return Object.assign({}, tab, {
                                thumb: tab.sale_amount,
                                refigned_payout: tab.payout,
                                refigned_net_payout: tab.net_payout,
                            });
                        });
                        this.summarytab = this.refignedSummaryTabs;
                        let uniqueSummary = Object.values(
                            this.summarytab.reduce((r, o) => {
                                r[o.advertiser] = r[o.advertiser] || o;
                                return r;
                            }, {})
                        );
                        this.summarytab = uniqueSummary.map((item, i) => {
                            return Object.assign({}, item, {
                                showData: false,
                                multipleData: this.summarytab.filter(function (curr) {
                                    if (curr.advertiser === item.advertiser) {
                                        return curr;
                                    }
                                }),
                                conversions: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return Number(acc) + Number(item.conversions);
                                    }, 0),
                                thumb: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return (
                                            parseFloat(acc) + parseFloat(item.thumb.replace(/,/g, ""))
                                        );
                                    }, 0),
                                refigned_payout: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return (
                                            parseFloat(acc) +
                                            parseFloat(item.refigned_payout.replace(/,/g, ""))
                                        );
                                    }, 0),
                                refigned_net_payout: this.summarytab
                                    .filter(function (curr) {
                                        if (curr.advertiser === item.advertiser) {
                                            return curr;
                                        }
                                    })
                                    .reduce((acc, item) => {
                                        return (
                                            parseFloat(acc) +
                                            parseFloat(item.refigned_net_payout.replace(/,/g, ""))
                                        );
                                    }, 0),
                            });
                        });
                        // this.summarytab.sale_amount = this.summarytab.sale_amount.replace(
                        //   /\B(?=(\d{3})+(?!\d))/g,
                        //   ","
                        // );
                        let coupon_arr = [];
                        let adver_arr = [];


                        // setTimeout(() => {
                        //   this.fetchData();
                        // }, 500);
                        row.forEach((r, k) => {
                            let arr_conv = [];
                            coupon_arr.push(r.coupon_code);
                            adver_arr.push(r.offer_name);
                            row.forEach((element) => {
                                if (r.coupon_code == element.coupon_code) {
                                    arr_conv.push(parseFloat(element.payout));
                                }
                            });
                            row[k].conversions = arr_conv.length;
                            // row[k].pay_out = row[k].payout;
                            // arr_conv = [];
                        });

                        this.order_arr = row; // Order Data
                        console.log('orders 1' + order_arr.length);
                        this.order_arr = row.map((x) => {
                            return {
                                coupon_code: x.coupon_code,
                                advertiser: x.advertiser,
                                customer_type: x.customer_type,
                                payout: x.payout,
                                sale_amount: x.sale_amount,
                                country: x.country,
                                Order_date: x.time ? x.time : "-",
                            };
                        });
                        this.all_data = row; // All Data for sorting by date filter

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }


        },
        beforeMount() {
            this.fetchCurrentMonthData();
            // this.fetchWholeData();
        },
        created() {
            this.affiliate_id = window.localStorage.getItem("affiliate_id");
            this.fetchWholeData();
            // this.get_coupon_advertiaer_list();
        },
        computed: {
            ...mapGetters(["layoutConfig"]),
            loaderLogo() {
                return process.env.BASE_URL + this.layoutConfig("loader.logo");
            },
        },
    };
</script>

<style lang="scss">
    .bg-light {
        background: unset !important;
    }

    #kt_content .ddd button.btn.btn-secondary {
        display: block !important;
        margin-top: 15px;
        background: #000 !important;
        border-color: #000 !important;
        color: #fff;
    }

    .load-more, .load-more:hover {
        color: #fff;
        background-color: black;
        border-color: black;
        margin: 0% 40%;
        width: 20%;
    }

    .notify-popup-list {
        color: #3f4254 !important;
    }

    .notify-confirm-class {
        margin: 0px !important;
    }

    .notify-confirm-class .swal2-confirm.swal2-styled {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20%;
    }

    .notify-confirm-class .swal2-cancel.swal2-styled {
        padding: 0px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background: transparent !important;
        position: absolute;
        top: 13px;
        right: 10px;
        margin-left: 15px;
        color: #000 !important;
        border-radius: 15px !important;
        font-size: 25px;
        font-weight: 600;
        width: 15px;
        height: 15px;
    }

    .h-notify-count {
        background: #f56160;
        color: #fff;
        border-radius: 10px;
        width: 25px;
        font-size: 12px;
    }

    .btn-mobile-profile ul {
        width: auto !important;
    }

    .dash-z-index {
        z-index: unset !important;
    }

    .table-responsive {
        display: inline-table !important;
    }

    .btn-div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .update-btn {
        }

        .export-btn {
            button {
                background: linear-gradient(rgb(3, 3, 53), rgb(11, 28, 102));
                border: none;
                border-radius: 6px;
                font-size: 13px;
                color: #fff;
                padding: 7px 15px;
            }
        }
    }

    .offer-container {
        text-align: center !important;
        min-width: fit-content !important;
    }

    .offer-container .dropdown-item:active {
        background-color: #ebedf3 !important;
    }

    .offer-link:hover {
        background-color: transparent !important;
    }

    .offer-sort-btn {
        font-size: 13px !important;
        font-weight: normal !important;
        line-height: normal !important;
        color: inherit !important;
        background-color: transparent !important;
    }

    .offer-text {
        margin-right: 10px !important;
    }

    .offer-link {
        padding: 0.25rem 0.25rem !important;
    }

    .offer-link:hover {
        color: #0f2753 !important;
    }

    #filter_modal___BV_modal_body_ {
        padding: 20px;
        overflow: auto;
    }

    .invoiceLabels {
        width: 30%;
    }

    .invoiceLabels ul li {
        padding: 7.5px 7px;
        margin-right: 5px;
        cursor: pointer;
        border-top: 2px solid #949494;
        transition: 0.2s ease-in-out;
    }

    .invoiceLabels ul li:last-child {
        border-bottom: 2px solid #949494;
    }

    .invoiceLabels ul li:hover {
        background: #d5d5d5;
    }

    .invoiceLabels ul li.active {
        background: #383b5d;
        color: #fff;
    }

    .container-fluid {
        padding: 0px !important;
    }

    .table-div {
        // max-height: 420px;
        // overflow: hidden;
        // overflow-y:auto;
        table {
            thead {
                padding: 0px;
            }

            tbody {
                tr {
                    .long-column-bottom {
                        min-width: 180px;
                        font-weight: bold;
                        vertical-align: middle;
                    }

                    .long-column {
                        min-width: 180px;

                        // vertical-align: bottom;
                        h3 {
                            font-weight: bold;
                            margin: 0px;
                            font-size: 19px;
                        }
                    }

                    td {
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768) {
        .table {
            border: none;
        }
    }

    @media screen and (max-width: 767.9px) {
        .table thead th {
            padding: 5px 10px;
        }

        .table thead th span {
            font-size: 10px;
            white-space: nowrap;
        }

        .table tbody tr td {
            //  word-break: break-all;
        }

        .display-none {
            display: none !important;
        }

        .mobile-data-grid div {
            margin: 0px 15px;
            // overflow: scroll;
        }

        .i-mobile-data-grid {
            margin: 0px 15px;
        }
    }

    @media screen and (max-width: 768) {
        .table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: 0.625em;
        }
    }

    @media screen and (max-width: 768) {
        .table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    @media only screen and (max-width: 768px) {
        .table-coupon thead {
            display: none;
        }

        .table-coupon tr {
            border-bottom: 15px solid #f0f4f7;
        }

        .table-coupon td {
            border-bottom: 1px solid #ddd;
            display: flex;
            display: -webkit-flex;
            display: -ms-flexbox;
            font-size: 0.8em;
            text-align: right;
        }

        .table-coupon td::before {
            content: attr(data-label);
            display: block;
            width: 100%;
            text-align: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        .table-coupon td:last-child {
            border-bottom: 0;
        }
    }

    .body-content {
        position: unset !important;
    }

    #kt_content {
        min-height: 100vh !important;
        padding-top: 50px;
        height: unset !important;
    }

    .crone-alert {
        width: 100%;
        background-color: #000;
        color: #fff;
        padding: 5px 5px 5px 10px;
        border-radius: 5px;
        margin: 10px 0px;
        font-weight: 600;
    }

    .filterButtonGroup {
        display: flex;
        width: 100%;
    }

    .filterButtonGroup button {
        margin-right: 5px;
    }

    .badge {
        color: white;
        border-radius: 5px;
    }

    .new-tag-bg {
        background: #0253b0;
        border-color: #0253b0;
    }

    .comfirmed-tag-bg,
    .approved-tag-bg {
        background: #00c778;
        border-color: #00c778;
    }

    .cancelled-tag-bg {
        background: #707070;
        border-color: #707070;
    }

    .export_file {
        float: right;
        cursor: pointer;
    }

    .s_date {
        // margin-right: 4%;
        cursor: pointer;
    }

    .select {
        color: #000;
        font-weight: 700;
    }

    //   .plan-boxes {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //   }
    // }

    // @media only screen and (max-width: 600px) {
    //   .plan-box {
    //     height: 40%;
    //     position: relative;
    //   }
    //   .logo{
    //     width: 80% !important;
    //   }
    // }
    .modal-btn {
        width: 100%;
        color: white;
        background: #000;
        border-radius: 0px;
    }

    .tabs {
        display: flex;
        text-align: center;
        font-weight: 600;
        border-bottom: 1px solid #e8e1e1;

        .tab {
            cursor: pointer;
        }

        .active {
            border-bottom: 1.5px solid black;
        }
    }

    @media only screen and (max-width: 450px) {
        .tabs {
            display: table !important;
            margin: auto;
        }
    }

    // for destop
    @media only screen and (min-width: 1200px) {
        .plan-boxes {
            span:first-of-type {
                margin-bottom: 7%;
            }
        }

        .plan-box {
            background-color: #ffffff;
            min-height: 200px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            span:first-of-type {
                display: block;
                text-transform: uppercase;
                color: #000;
                font-weight: bold;
                font-size: 16px;
            }

            h1 {
                font-weight: bold;
                color: #000;
                font-size: 4rem;
                margin: 10px 0;
            }

            span:last-of-type {
                display: block;
                text-transform: capitalize;
                color: #000;
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .table thead th,
        .table tbody td {
            white-space: unset;
        }

        .last_refreshed {
            color: black !important;

            // margin-top: 220px;
            icon {
                color: white;
            }

            span {
                margin-left: 1rem;
            }
        }
    }

    .ddd {
        margin-top: -2.5rem !important;
    }

    @media only screen and (max-width: 1200px) {
        .last_refreshed {
            color: #19248d;
            margin-top: 0px !important;

            span {
                margin-left: 1rem;
            }
        }
    }

    @media only screen and (max-width: 1600px) {
        .last_seen {
            color: white;
            float: right;

            span {
                margin-left: 1rem;
            }
        }
    }

    //for screens bigger then mobile
    @media only screen and (min-width: 1600px) {
        .last_seen {
            color: black;
            float: right;

            span {
                margin-left: 1rem;
            }
        }
    }

    // for mobile
    @media only screen and (max-width: 1200px) {
        .offer-text {
            margin-right: 0px !important;
        }

        .plan-boxes {
            margin-bottom: 5%;
            display: inline;

            span:last-of-type {
                float: right;
            }
        }

        .plan-box {
            background-color: #ffffff;
            width: 45%;
            min-height: 100px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            span:first-of-type {
                display: block;
                text-transform: uppercase;
                color: #000;
                font-weight: bold;
                font-size: 12px;
            }

            h1 {
                font-weight: bold;
                color: #000;
                font-size: 2rem;
                margin: 10px 0;
            }

            span:last-of-type {
                display: block;
                text-transform: capitalize;
                color: #000;
                font-weight: bold;
                font-size: 12px;
            }
        }

        #kt_content .ddd {
            display: none;
        }

        .header-mobile-fixed .header-mobile {
            height: 350px !important;
        }

        #kt_content .container-fluid {
            margin-top: -240px;
            position: relative;
            // z-index: 99;
            z-index: unset !important;
        }

        .plan-box span:last-of-type {
            font-size: 16px;
        }

        .plan-box h1 {
            font-size: 3rem;
        }

        .btn-div.mt-5.mobile {
            display: flex !important;
            justify-content: space-between !important;
            width: 100%;
            padding: 0 10px 0 0;
        }

        .btn-div.mt-5.mobile button.btn.btn-secondary {
            background: transparent;
            border: 0;
        }

        .dropdown.mobile.d-lg-none.d-block.p-2 {
            display: none !important;
        }
    }

    .light_gray {
        color: rgb(181, 181, 181);
        padding: 0px;
    }

    .toggle_buttons {
        border: 2px solid rgb(121, 121, 121) !important;
        border-radius: 5px !important;
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    @media only screen and (max-width: 600px) {
        .toggle_buttons {
            width: 6rem !important;
            font-size: 0.8rem !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .toggle_buttons {
            width: 7rem !important;
            height: 4rem !important;
            font-size: 0.85rem !important;
            margin: 0.1rem !important;
        }
    }

    @media only screen and (max-width: 363px) {
        .toggle_buttons {
            width: 6rem !important;
            height: 2.5rem !important;
            font-size: 0.7rem !important;
        }
    }

    @media only screen and (max-width: 305px) {
        .toggle_buttons {
            width: 4rem !important;
            height: 3rem !important;
            font-size: 0.5rem !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .table_res_head {
            display: none !important;
        }
    }

    #subparent .tab_buttons {
        border: none !important;
        border-radius: 5px !important;
        color: white;
        background-color: #ff6633 !important;
    }

    #parent .tab_buttons {
        border: none !important;
        border-radius: 5px !important;
        color: #000;
        background-color: #ff6633 !important;
    }

    .active_status {
        // background-color: #00c778;
        //   padding: 0.2rem 1.8rem;
        padding: 2px 10px;
        border-radius: 0.3rem;
        width: 100%;
        text-align: center;
    }

    .expired_status {
        // background-color: #f59253;
        //   padding: 0.2rem 1.8rem;
        padding: 3px 5px;
        border-radius: 0.3rem;
        width: 100%;
        text-align: center;
    }

    .status_coupon {
        color: rgb(163, 137, 137);
        border-radius: 0.3rem;
    }

    .modal-content {
        bottom: unset !important;
    }

    @media (max-width: 767px) {
        .bar span {
            font-size: 11px;
        }
    }

    // Changes

    div#filter_modal___BV_modal_outer_ {
        z-index: 9999999 !important;
    }

    @media (min-width: 1200px) {
        p.last_refreshed {
            color: #000 !important;
            // margin-top: 110px !important;
            position: relative;
            z-index: 99;
            padding-left: 0;
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 8px;
            width: 70%;
        }

        p.last_refreshed + .btn-div.mt-5 {
            position: relative;
            z-index: 999;
            padding-left: 0;
            margin-top: 0 !important;
            width: 70%;
        }

        p.last_refreshed + .btn-div.mt-5 .export-btn {
            // display: none;
        }

        p.last_refreshed + .btn-div.mt-5 .update-btn button {
            font-size: 12px;
            padding: 6px 10px;
            border-radius: 6px;
        }
        .content-wrapper {
            overflow: scroll;
        }
        .content-wrapper > div > .row {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 0 !important;
        }

        .content-wrapper .plan-boxes {
            max-width: none !important;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: -93px;
            position: relative;
            // z-index: 999;
            width: 80%;
            margin-left: 20%;
            margin-bottom: 17px;
        }

        .content-wrapper .plan-boxes + div {
            max-width: 100% !important;
            flex: none;
            position: relative;
            z-index: 999;
        }

        .plan-boxes span {
            min-height: auto;
            min-width: 140px;
            padding: 5px;
            margin-bottom: 0 !important;
        }

        .plan-box h1 {
            margin: 0;
            font-size: 30px;
        }

        span.plan-box:first-of-type {
            margin-right: 10px;
        }

        #kt_content > div > .container-fluid {
            max-width: 1185px;
            width: 100%;
        }

        .inf-table {
            padding-top: 0 !important;
        }

        .v-btn:before {
            display: none;
        }

        .v-btn-toggle > .v-btn.v-btn {
            opacity: 1 !important;
            height: 40px !important;
            border-width: 1px !important;
        }

        .inf-table p.table-head {
            font-size: 18px;
            color: #000;
        }

        .inf-table .tabs {
            border: 0;
        }

        .inf-table p.table-head span {
            font-size: 10px;
            position: relative;
            top: -10px;
            opacity: 0.3;
        }

        .data_grid {
            margin-top: 0 !important;
        }

        .table thead th,
        .table tbody td {
            border: 0 !important;
        }

        .table-div .table-bordered {
            border: 0 !important;
        }

        .modal .modal-header h4 {
            color: #000;
            font-weight: bold;
            font-size: 20px;
        }

        .modal .modal-header {
            border: 0;
            padding-bottom: 0;
        }

        div#filter_modal___BV_modal_body_ > .row > .col-12:first-child {
            color: #000;
            font-weight: bold;
        }

        div#filter_modal___BV_modal_body_ > .row label {
            font-weight: bold;
            color: #000;
        }

        div#filter_modal___BV_modal_body_ > .row > div input {
            color: #000;
        }

        .update-btn span {
            font-size: 11px;
            color: #000;
        }

        .update-btn span i {
            color: #000;
            font-size: 11px;
            margin-left: 5px;
            -webkit-transform: rotateY(160deg) rotate(12deg);
            transform: rotateY(160deg) rotate(12deg);
        }

        tr.offers {
            display: none;
        }

        .active_status {
            // background-color: #00c778;
            padding: 0px 15px 1px;
            width: auto;
            text-align: center;
            // color: #fff;
            line-height: normal;
            border-radius: 0;
            font-weight: normal;
        }

        .expired_status {
            padding: 0px 5px 1px;
            width: auto;
            text-align: center;
            // color: #fff;
            line-height: normal;
            border-radius: 0;
            font-weight: normal;
        }

        .table thead th {
            text-align: left !important;
            font-size: 12px !important;
            font-weight: normal;
        }

        td.for-desk {
            width: 200px;
        }

        td.advertise {
            width: 250px;
        }

        .data_grid table {
            border: 0;
        }

        .data_grid table tr td {
            font-weight: bold;
        }

        .content-wrapper .plan-boxes {
            margin-bottom: 29px;
        }

        // div#filter_modal___BV_modal_body_ > .row > div {
        //     margin-bottom: 24px;
        // }
        div#filter_modal___BV_modal_outer_ footer#filter_modal___BV_modal_footer_ {
            padding-top: 0;
        }

        .v-card.v-sheet.theme--light .v-list-item.theme--light {
            padding-left: 0;
        }

        p.last_refreshed + .btn-div.mt-5 .update-btn button {
            display: block !important;
            margin-top: 15px;
        }

        .update-btn {
            display: flex;
            flex-direction: column-reverse;
        }

        div#modal-1___BV_modal_outer_ {
            z-index: 9999999 !important;
        }

        .table thead th {
            width: 200px;
        }

        .table tbody td {
            text-align: left;
        }

        input.overlay-hide-radio {
            display: none !important;
        }
        .hide {
            visibility: hidden !important;
        }

        .overlay {
            display: none !important;
        }
    }

    form.form .btn.btn-dark.form-control {
        margin-top: 20px;
    }

    .form-group label {
        font-size: 13px !important;
        font-weight: bold !important;
        color: #000 !important;
    }

    footer#filter_modal___BV_modal_footer_ .modal-btn.btn-dark {
        border-radius: 6px;
    }

    @media (max-width: 1200px) {
        #kt_content .ddd {
            display: none;
        }

        .header-mobile-fixed .header-mobile {
            height: 350px !important;
        }

        #kt_content .container-fluid {
            margin-top: -240px;
            position: relative;
            z-index: 99;
        }

        .plan-boxes .plan-box {
            padding: 0 10px;
        }

        .plan-box span:last-of-type {
            font-size: 16px;
        }

        .plan-box h1 {
            font-size: 3rem;
        }

        .btn-div.mt-5.mobile {
            display: flex !important;
            justify-content: space-between !important;
            width: 100%;
            padding: 0 10px 0 0;
        }

        .btn-div.mt-5.mobile button.btn.btn-secondary {
            background: transparent;
            border: 0;
        }

        .dropdown.mobile.d-lg-none.d-block.p-2 {
            display: none !important;
        }

        .v-btn-toggle--group > .v-btn.v-btn {
            margin: 0 !important;
            padding: 0 !important;
            border: 0 !important;
            border-radius: 0 !important;
            min-width: auto !important;
            width: 80px !important;
            font-size: 13px !important;
            display: block !important;
            margin-right: 18px !important;
            border-bottom: 2px solid transparent !important;
            margin-right: 6px !important;
            padding-bottom: 5px;
            color: #8080808c !important;
        }

        #parent .tab_buttons {
            color: #151a4b !important;
            background-color: transparent !important;
            min-width: auto !important;
            width: 80px !important;
            font-size: 13px !important;
            border-bottom: 2px solid #ff6633 !important;
            border-radius: 0 !important;
            font-weight: bold !important;
        }

        .v-btn:before {
            display: none;
        }

        .v-btn-toggle--group {
            display: flex !important;
            flex-wrap: nowrap !important;
            overflow-x: auto;
            width: 270px !important;
            padding: 0 0;
            padding-bottom: 5px;
        }

        .v-btn__content {
            display: inline-block;
        }

        .modal-content {
            bottom: 0 !important;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .data_grid .alert.alert-primary {
            width: 90%;
            margin: 10px auto;
        }

        .table tbody tr td {
            font-weight: bold;
        }

        .table tbody tr.offers td {
            font-weight: normal !important;
            opacity: 0.8;
        }

        .active_status {
            // color: #fff;
            // padding: 0px 5px !important;
            width: auto;
        }

        .expired_status {
            // color: #fff;
            padding: 0px 3px;
        }

        #parent .toggle_buttons {
            height: 36px !important;
            width: auto !important;
            margin-right: 30px !important;
        }

        .table thead th {
            vertical-align: top;
            font-size: 13px !important;
        }

        header#filter_modal___BV_modal_header_ > h4 {
            font-size: 22px;
            color: #000;
            font-weight: bold;
        }

        div#filter_modal___BV_modal_body_ > .row .col-12:first-child {
            font-weight: bold;
            font-size: 14px;
            color: #000;
        }

        div#filter_modal___BV_modal_body_ > .row .col-12 label {
            font-weight: bold;
            font-size: 14px;
        }

        th.light_gray {
            text-align: left !important;
        }

        div#filter_modal___BV_modal_body_ > .row > div:nth-child(2) {
            padding-right: 0;
            -ms-flex: 0 0 42.666667%;
            flex: 0 0 45.666667%;
            max-width: 45.666667%;
        }

        div#filter_modal___BV_modal_body_ > .row > div:nth-child(3) {
            padding-left: 0;
            padding-right: 0;
            -ms-flex: 0 0 15.666667%;
            flex: 0 0 12.666667%;
            max-width: 12.666667%;
        }

        div#filter_modal___BV_modal_body_ > .row > div:nth-child(4) {
            padding-right: 0;
            padding-left: 0;
        }

        div#filter_modal___BV_modal_body_ {
            padding-bottom: 0;
            padding-top: 0;
        }

        header#filter_modal___BV_modal_header_ {
            padding-bottom: 10px;
        }

        div#filter_modal___BV_modal_body_ > .row .col-12:first-child {
            padding-bottom: 0;
        }

        .plan-box {
            width: 47%;
        }

        .plan-box h1 {
            margin-top: 0;
        }

        .plan-box span:last-of-type {
            font-size: 15px;
            padding-bottom: 5px;
        }

        .table thead th,
        .table tbody td {
            border: 0 !important;
        }

        .table-div .table-bordered {
            border: 0 !important;
        }

        th.light_gray.desk {
            display: none;
        }
        /// Added by devangi to show coupon for mobile view
        td.for-desk:nth-child(3) {
            display: none;
        }
        td.for-desk:nth-child(2) {
            display: block !important;
        }

        .tabs {
            border: 0;
        }

        .inf-table {
            padding-top: 0 !important;
        }

        .tabs {
            padding-bottom: 0 !important;
        }

        .data_grid table.table {
            margin-top: 10px !important;
            border: 0;
        }

        #kt_content .container-fluid {
            margin-top: -288px;
        }

        .inf-table .tabs {
            padding-bottom: 0 !important;
        }

        .data_grid {
            margin-top: 20px !important;
        }

        div#filter_modal___BV_modal_body_ > .row > div {
            margin-bottom: 18px;
        }

        .modal {
            z-index: 999999999;
        }

        #kt_content .ddd {
            display: block;
            padding-left: 8px;
        }

        #kt_content .ddd p.last_refreshed {
            display: none;
        }

        #kt_content .ddd .export-btn {
            // display: none;
        }

        #kt_content .ddd span {
            display: none;
        }

        #kt_content .ddd button.btn.btn-secondary {
            display: block !important;
            margin-top: 15px;
            background: #000 !important;
        }

        div#modal-1___BV_modal_content_ {
            height: 100vh;
            overflow-y: auto;
        }

        .header-mobile-fixed .header-mobile {
            height: 410px !important;
        }

        #kt_content .container-fluid {
            margin-top: -286px;
            position: relative;
            z-index: 99;
            width: 100%;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }

        div#modal-1___BV_modal_outer_ {
            position: static !important;
            height: 100%;
            z-index: 99999 !important;
        }

        div#modal-1___BV_modal_outer_ .modal-dialog.modal-md {
            height: 100%;
            max-width: 100% !important;
            width: 100% !important;
            margin: 0 !important;
            pointer-events: auto !important;
            position: static !important;
        }

        div#modal-1___BV_modal_outer_ header {
            pointer-events: auto;
        }

        div#filter_modal___BV_modal_outer_ {
            position: static !important;
            height: 100%;
            width: 100%;
        }

        div#filter_modal___BV_modal_outer_ .modal-dialog.modal-md {
            max-width: 100% !important;
            width: 100% !important;
            height: 100% !important;
            z-index: 9999;
            display: flex;
            margin: 0;
            align-items: flex-end;
        }

        div#filter_modal___BV_modal_outer div#filter_modal___BV_modal_content {
            position: static;
            height: 90%;
            overflow-y: auto;
        }

        div#filter_modal___BV_modal_outer div#filter_modal___BV_modal_content footer {
            border: 0;
        }

        div#filter_modal___BV_modal_content_ {
            position: static;
            height: 90%;
            overflow-y: auto;
        }

        .table tbody td {
            width: auto !important;
            text-align: left;
        }

        .table thead th {
            width: 200px;
        }

        .data_grid table tbody tr ~ tr.offers ~ tr:not(.offers) {
            border-bottom: 0;
        }

        .dash-table table tbody tr.couponClass:first-child {
            border-bottom: 5px solid #fff !important;
        }

        #kt_content > .d-flex.flex-column-fluid {
            top: 0 !important;
        }
    }

    .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
    .v-btn__content {
        opacity: 1;
    }

    div#modal-1___BV_modal_content_ button.close {
        font-size: 40px;
        line-height: normal;
        opacity: 1;
    }

    .mx-datepicker-popup {
        z-index: 9999999 !important;
    }

    .d_data_grid {
        overflow-y: auto !important;
    }

    .data_grid .v-card.v-sheet.theme--light {
        box-shadow: none !important;
    }

    // .inf-table table.table.table-bordered {
    //     width: 100%;
    // }

    .inf-table table.table.table-bordered tbody tr td:nth-child(2) {
        text-align: left;
    }

    .fltr span span span {
        font-family: Gilory, Helvetica, Arial;
        font-weight: normal;
    }

    .inf-table .tabs {
        padding-bottom: 10px !important;
    }

    .data_grid table {
        margin-top: 10px !important;
        background: #fff2f2;
        height: auto;
    }

    .data_grid table tbody tr {
        background: white;
        border-bottom: 5px solid #fff2f2;
    }

    .data_grid table tbody tr td {
        vertical-align: middle;
    }

    .active_status {
        background: transparent;
        color: #3f4254;
        position: relative;
        padding-left: 15px !important;
    }

    .expired_status {
        background: transparent;
        color: #3f4254;
        position: relative;
        padding-left: 15px !important;
        width: auto;
    }

    .active_status:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 5px;
        left: 2px;
        background: #00c778;
        border-radius: 50%;
        box-shadow: 0 0 3px #00c778;
    }

    .expired_status:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 5px;
        left: 2px;
        background: red;
        border-radius: 50%;
        box-shadow: 0 0 3px red;
    }

    .table thead th:last-child {
        text-align: right !important;
    }

    .data_grid table tbody tr td:last-child {
        text-align: right !important;
    }

    .active_status {
        padding-right: 0;
    }

    label.expired_status {
        padding-right: 0;
    }

    .data_grid table tbody tr.offers td {
        text-align: left !important;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffff;
    }

    .overlay p {
        margin: 0;
        font-size: 25px;
    }

    .overlay img {
        width: 80px;
        margin: 0 auto;
        margin-bottom: 10px;
        animation-name: anim;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    .overlay-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input.overlay-hide-radio {
        position: absolute;
        top: -143px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9999999;
    }

    input.overlay-hide-radio:checked {
        display: none;
    }

    input.overlay-hide-radio:checked + div {
        display: none;
    }

    @keyframes anim {
        0% {
            transform: translateX(-50%);
        }

        100% {
            transform: translateX(50%);
        }
    }

    #kt_content > .d-flex.flex-column-fluid {
        position: relative;
        top: 100px;
        margin-bottom: 100px;
        // z-index: 99999;
    }

    .page-loader {
        width: 100%;
        height: 100%;
        z-index: 999999;
        justify-content: center;
        top: -10px;
    }

    body.modal-open {
        overflow: initial !important;
    }

    .modal.fade .modal-dialog {
        -webkit-transform: none !important;
        transform: none !important;
    }

    .swal2-container.swal2-center {
        z-index: 999999;
    }

    .swal2-styled.swal2-confirm {
        color: #ff6633 !important;
    }

    .notify-container-class {
        display: flex;
        z-index: 1 !important;
        background: rgba(0, 0, 0, 0.5) !important;
    }

    .notify-popup-class {
        padding: 0px !important;
        z-index: 99999999999 !important;
        border-radius: 0.3125em;
        overflow: hidden;
    }

    .notify-header-class {
        display: block !important;
        padding: 20px !important;
        background: #ebebeb !important;
        border-bottom: 1px solid #121212;
    }

    .notify-title-class {
        margin: 0px !important;
        color: #5f5c62 !important;
        font-weight: 600 !important;
        font-size: 1.5rem;
    }

    .notify-content-class {
        margin: 0px !important;
        padding: 0px !important;
        text-align: left;
    }

    .notify-message-class {
        padding: 20px !important;
        border-bottom: 1px solid #f4f5f8;
        text-align: left !important;
    }

    .notify-message-class .bold {
        font-weight: 600;
    }

    .custom-zIndex {
        z-index: 0;
    }

    .plan-box {
        background: #000 !important;
        color: #fff !important;
    }

    .plan-box span:last-of-type,
    .plan-box h1 {
        color: #fff !important;
    }

    .d_data_grid a {
        color: #ff6633;
    }

    .offers-icon {
        width: 25px;
        height: 25px;
    }

    .btn.btn-primary {
        color: #000;
        background-color: #ff6633;
        border-color: #ff6633;
    }
</style>
